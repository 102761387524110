import Image from 'next/image';

const Portraits = () => {
  return (
    <div className="w-full">
      <Image
        src={'/assets/luckyAnts.gif'}
        width={210}
        height={210}
        alt="Animated portraits of Lucky Ants"
        className="inline-flex rounded-lg ring-black object-cover object-top"
      />
    </div>
  );
};

export default Portraits;
