import { TOTAL_ANT_COUNT } from './constants.js';

const sortByValue = (a, b) => b[1] - a[1];

const sortBySize = (a, b) => Object.keys(b[1]).length - Object.keys(a[1]).length;

const TraitBreakdown = ({ breakdowns }) => {
  return (
    <table className="table-auto">
      <tbody>
        {Object.entries(breakdowns)
          .sort(sortByValue)
          .map(([name, count], index) => {
            return (
              <tr key={name}>
                <td className="sm:text-lg p-1">{name}:</td>
                <td className="text-sm sm:text-base font-bold pl-6 pr-6">{count}</td>
                <td className="text-sm p-1">{((count / TOTAL_ANT_COUNT) * 100).toFixed(2)}%</td>
              </tr>
            );
          })}
      </tbody>
    </table>
  );
};

const Rarity = ({ rarityBreakdowns }) => {
  return (
    <div className="text-md" id="#rarity">
      <h3 className="font-bold text-lg sm:text-xl md:text-2xl tracking-tight pb-6">
        Rarity Breakdown
      </h3>
      <div className="flex flex-wrap justify-start text-gray-300">
        {Object.entries(rarityBreakdowns)
          .sort(sortBySize)
          .map(([key, breakdowns]) => {
            return (
              <div
                key={key}
                className="flex flex-col items-center max-w-lg m-2 sm:m-4 p-6 shadow-lg rounded-lg bg-gradient-to-b from-green-900 to-blue-900"
              >
                <div className="font-mono text-lg font-bold">{key}</div>
                <div className="flex flex-col">
                  <TraitBreakdown breakdowns={breakdowns} />
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default Rarity;
