import { MINT_PRICE, TOTAL_ANT_COUNT } from './constants.js';

const MissionStatement = () => {
  return (
    <div className="text-md" id="#mission">
      <h3 className="font-bold text-lg sm:text-xl md:text-2xl tracking-tight opacity-40 pb-4">
        Our Mission
      </h3>
      <div>
        <p>
          Lucky Ants is an NFT collection of {TOTAL_ANT_COUNT} colorful and cute-looking ants living
          out their best lives on the Solana blockchain. Watch out! Armadillos are right around the
          corner to slurp them up if you are not careful! But wait, we will not let our fellow ants
          get eaten without putting up a fight. There will be opportunities to save some of them.
          Check out our FAQs for additional details!
        </p>
        <p className="pt-4">
          Not only do we want to share our artworks with you, but we also want to build a great
          community around the project where people want to be a part of! We will be organizing
          events and adding new features so that Lucky Ants goes beyond just artwork. We aspire to
          be one of the &quot;blue chip&quot; projects. However, we can&apos;t do that all by
          ourselves. We need your support! Join our Discord server, follow us on Twitter, and
          acquire a Lucky Ant! We promise that we will work hard, like the ants in nature, on this
          project and for our Ants community!
        </p>
        <p className="pt-4">
          Thank you and welcome to the Ants community! Let&apos;s have an Ant-tastic time together!
        </p>
        <h3 className="font-bold text-lg sm:text-xl md:text-2xl tracking-tight opacity-40 pt-8">
          Why Lucky Ants?
        </h3>
        <div className="pt-4">
          <p>💪 Strong team</p>
          <p>😊 Cute looking</p>
          <p>🌈 Super colorful</p>
          <p>🎟️ Community Events</p>
          <p>🌋 Floor Burning ( Armadillos )</p>
          <p>🔨 Weekly Raffle ( Jailbreak )</p>
          <p>🎉 Future holder perks ( Antlings )</p>
        </div>
      </div>
    </div>
  );
};

export default MissionStatement;
