const Lore = () => {
  const titleClassName =
    'font-bold text-lg sm:text-xl md:text-2xl tracking-tight pb-2 text-gray-500';
  const containerClassName = 'pt-4 pb-4';
  return (
    <div id="#lore">
      <div className={containerClassName}>
        <h3 className={titleClassName}>Our Colony</h3>
        <p>
          Many years ago, an asteroid from outer space struck our planet and crashed in the middle
          of the ocean. From the impact, an island formed almost overnight with the asteroid
          becoming a hill in the middle. A mysterious source of colorful liquid started to ooze from
          the space rock and gradually merged with rainwater to form a stream that curves and cuts
          into the landscape. Everything touched by the water began to display a tremendous amount
          of color. Soon, a diverse group of wildlife emerged and began to call this island home.
        </p>
        <p className="pt-3">
          Among them, a colony of ants live in their anthills near the river bank. These ants are
          held in great reverance by the local population and is believed to have brought the
          natives great fortune. The locals called them Lucky Ants. The ant queens are busy
          nurturing their young while the worker ants move about collecting resources for the
          colony. For the most part, the Lucky Ants enjoy a carefree life as the island provides
          abundant food for them. Their daily interaction with the space-liquid infused vegetation
          has bleached their arms into colors different from their body. Every week, the ants would
          come out in droves and party hard to celebrate their harvest. Life is almost perfect for
          the Lucky Ants...
        </p>
      </div>
      <div className={containerClassName}>
        <h3 className={titleClassName}>Armadillos</h3>
        <p>
          Of course, no one lives a life without challenges. That is true for the Lucky Ants. The
          parties and mass ant movements have attracted the attention of predators! The ants are on
          a constant lookout for armadillos looking to snack on them. They have built several
          defensive systems in their anthills with multiple exit tunnels and isolated hideouts to
          protect themselves. But alas, it&apos;s not enough! Armadillos are quick to snatch up any
          slow Lucky Ants on the ground. On a positive note, if you could call it that, the
          mysterious water has made Lucky Ants super nutritious. Eating one is enough to satisfy an
          armadillo for a week. Often, the Lucky Ants slurped up by the armadillos do not get
          consumed right away but locked away for future needs. Now, that provided opportunities to
          rescue some of the captured ones.
        </p>
      </div>
      <div className={containerClassName}>
        <h3 className={titleClassName}>The Jailbreak</h3>
        <p>
          After each armadillo attack, the queen ants dispatch a few light footed ants to stalk the
          armadillos and discover their resting ground. The ants would report back with a detailed
          map containing the location of their captured loved ones. The ant leaders would form a
          guerilla squad armed with drums, grappling hooks, and lock picks. Some brave ants use
          themselves as live baits by playing the drums to lure armadillos away from their nest.
          Others would then march deep into the armadillos&apos; territory to rescue their missing
          brethren. Some ants are saved from the camp. Others are lost forever to armadillos. Life
          on the island is a constant tug and pull of capture and rescue between the armadillos and
          the ants.
        </p>
      </div>
      <div className={containerClassName}>
        <h3 className={titleClassName}>Antlings</h3>
        <p>
          Whether it&apos;s the impact of the mysterious liquid, Darwinism, or both, there are
          rumors of Lucky Ants genetically mutating. Some murmured that these new breeds have extra
          body parts while others bragged about hardened shells to withstand the jaw of armadillos!
          We do not know if rumors are real or fake news as we don&apos;t have confirmed sightings!
          But if we can trust the news source, these new breeds of Lucky Ant offsprings are
          called...Antlings!
        </p>
      </div>
    </div>
  );
};

export default Lore;
