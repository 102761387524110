import Image from 'next/image';
import Team from './library/Team.js';
import Faqs from './library/Faqs.js';
import Lore from './library/Lore.js';
import Footer from './layout/Footer.js';
import Rarity from './library/Rarity.js';
import Banner from './library/Banner.js';
import Gallery from './library/Gallery.js';
import RoadMap from './library/RoadMap.js';
import Portraits from './library/Portraits.js';
import Promotion from './library/Promotion.js';
import LaunchWeek from './library/LaunchWeek.js';
import Scaffolding from './layout/Scaffolding.js';
import FancyContainer from './library/FancyContainer.js';
import MissionStatement from './library/MissionStatement.js';

const Home = ({ rarityBreakdowns }) => {
  return (
    <Scaffolding>
      <Banner />
      <FancyContainer>
        <MissionStatement />
      </FancyContainer>
      <FancyContainer>
        <Gallery />
        <Lore />
        <RoadMap />
        <Faqs />
      </FancyContainer>
      <FancyContainer>
        <Rarity rarityBreakdowns={rarityBreakdowns} />
      </FancyContainer>
      <FancyContainer>
        <Team />
      </FancyContainer>
      <Footer />
    </Scaffolding>
  );
};

export default Home;
