import Image from 'next/image';

const GALLERY_IMAGES = [
  '/assets/1.png',
  '/assets/2.png',
  '/assets/3.png',
  '/assets/4.png',
  '/assets/0.png',
  '/assets/5.png',
  '/assets/6.png',
  '/assets/7.png',
  '/assets/8.png',
];

const Gallery = ({ images = GALLERY_IMAGES, classes = {} }) => {
  return (
    <div className={`flex flex-col items-center justify-center ${classes.root}`}>
      <div className="mb-3 flex overflow-x-auto fancy-scroll-bar-horizontal max-w-xs sm:max-w-screen-sm md:max-w-screen-md lg:max-w-screen-lg xl:max-w-screen-xl 2xl:max-w-screen-2xl">
        <div className="flex flex-nowrap items-center justify-center">
          {images.map((url, index) => (
            <div key={url} className="inline-block px-3 mb-2">
              <div className="w-32 h-32 max-w-xs overflow-hidden rounded-lg shadow-md hover:shadow-xl transition-shadow duration-300 ease-in-out">
                <Image
                  src={url}
                  width={210}
                  height={210}
                  alt={`Lucky Ant example # ${index}`}
                  className="inline-flex rounded-lg object-cover object-top"
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Gallery;
