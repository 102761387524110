import Image from 'next/image';
import CheckIcon from '../icons/CheckIcon.js';
import UncheckedIcon from '../icons/SquareIcon.js';

const BOX_DIRECTION = {
  LEFT: 'LEFT',
  RIGHT: 'RIGHT',
};

const TrackIcon = () => {
  return (
    <Image
      src="/assets/antIcon.png"
      height={25}
      width={25}
      alt="Lucky Ant icon"
      className="inline-flex rounded-lg ring-black object-cover object-top"
    />
  );
};

const Box = ({ title, direction, children }) => {
  return (
    <div className="flex justify-center items-center">
      <div className="p-4 rounded-xl ml-auto">
        <h3 className="font-semibold text-lg mb-1 flex">
          <p className="pr-2">{title}</p>
          <div>
            <TrackIcon />
          </div>
        </h3>
        <p className="leading-tight text-justify">{children}</p>
      </div>
    </div>
  );
};

const Milestone = ({ isMet = false, children }) => {
  const Icon = isMet ? CheckIcon : UncheckedIcon;
  return (
    <span className="flex justify-start items-center pb-3 lg:pb-0">
      <Icon className="pr-2 text-3xl w-6 h-6" style={{ minWidth: '24px' }} />
      <span className="text-left">{children}</span>
    </span>
  );
};

const RoadMap = () => {
  return (
    <div className="text-md pt-10" id="#roadmap">
      <h3 className="font-bold text-lg sm:text-xl md:text-2xl tracking-tight pb-3">Roadmap</h3>
      <div className="flex flex-col lg:flex-row justify-evenly items-start text-center lg:text-left bg-gradient-to-b md:bg-gradient-to-r from-green-800 to-blue-900 rounded-lg">
        <Box title="Completed">
          <Milestone isMet={true}> Generate the collection</Milestone>
          <Milestone isMet={true}> Create social media accounts</Milestone>
          <Milestone isMet={true}> Stand up the website</Milestone>
          <Milestone isMet={true}> Upload collection to blockchain</Milestone>
          <Milestone isMet={true}> Collection traits breakdown</Milestone>
          <Milestone isMet={true}> Launch week giveaways and events!</Milestone>
          <Milestone isMet={true}> Marketplace listing</Milestone>
          <Milestone isMet={true}> Armadillos rollout</Milestone>
          <Milestone isMet={true}> Begin Jailbreak cadence</Milestone>
        </Box>
        <Box title="Coming soon">
          <Milestone> Make cosmetic changes to minted Lucky Ants</Milestone>
        </Box>
        <Box title="2022">
          <Milestone> Finalize Antling artwork</Milestone>
          <Milestone> Deploy Antlings</Milestone>
        </Box>
      </div>
    </div>
  );
};

export default RoadMap;
