import Image from 'next/image';
import Portraits from './Portraits.js';

const TeamMember = ({ name, description, image }) => {
  return (
    <div className="flex justify-center items-center sm:mb-8 pb-6 ml-6 lg:ml-8">
      <div className="flex overflow-hidden mr-4 items-center">{image}</div>
      <div className="flex flex-col items-left">
        <span className="pb-2 pt-2 font-ultra whitespace-nowrap">{name}</span>
        <span className="max-w-xs">{description}</span>
      </div>
    </div>
  );
};

const Team = () => {
  return (
    <div className="text-md" id="#team">
      <h3 className="font-bold text-lg sm:text-xl md:text-2xl tracking-tight pb-6">Team</h3>
      <div className="flex flex-col lg:flex-row justify-evenly items-start lg:items-center text-center lg:text-left">
        <TeamMember
          name="The Coder Ant"
          description="Software engineer, website developer"
          image={<Portraits />}
        />
        <TeamMember
          name="The Artist Ant"
          description="Artist, graphic designer"
          image={
            <Image
              src={'/assets/antArtist.png'}
              width={210}
              height={210}
              alt="Image of Lucky Ant with facial paintings and a crown"
              className="inline-flex rounded-lg ring-black object-cover object-top"
            />
          }
        />
      </div>
    </div>
  );
};

export default Team;
