import Homepage from '../components/index.js';
import { getRarityBreakdowns } from './api/rarities.js';

export async function getStaticProps(context) {
  const rarityBreakdowns = await getRarityBreakdowns();

  if (!rarityBreakdowns) {
    return {
      notFound: true,
    };
  }

  return {
    props: { rarityBreakdowns },
  };
}

export default function Home({ rarityBreakdowns }) {
  return <Homepage rarityBreakdowns={rarityBreakdowns} />;
}
