import { useState, useEffect } from 'react';

const Banner = () => {
  return (
    <>
      <div
        id="#topOfApp"
        className="w-11/12 rounded-lg relative bg-cover mt-6 mb-4 p-10 md:p-12"
        style={{
          backgroundImage:
            'linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url(/assets/anthill_low.png)',
        }}
      >
        <div className="flex w-full items-center justify-center text-white flex-col lg:mt-12 lg:mb-12">
          <h1 className="font-ultra text-3xl sm:text-6xl">Ant-tastic!</h1>
          <div className="hidden md:flex text-lg rounded-lg flex-col items-center flex-center mt-8 border-2 p-2">
            <div className="flex">
              <p className="pr-2">
                Minting is done, please visit
                <a
                  href="https://www.magiceden.io/marketplace/lucky_ants"
                  target="_blank"
                  rel="noreferrer"
                  className="underline text-blue-500 pl-1 pr-1"
                >
                  Magic Eden
                </a>
                <a
                  href="https://digitaleyes.market/collections/Lucky%20Ants"
                  target="_blank"
                  rel="noreferrer"
                  className="underline text-blue-500 pr-1"
                >
                  Digital Eyes
                </a>
                to purchase a Lucky Ant NFT!
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Banner;
