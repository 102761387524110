import Link from 'next/link';
import { CommunityAddress } from './DisplayAddress';

const QandA = [
  {
    question: 'How do I acquire a Lucky Ant NFT?',
    answer: (
      <div>
        Please visit
        <a
          href="https://www.magiceden.io/marketplace/lucky_ants"
          target="_blank"
          rel="noreferrer"
          className="underline text-blue-500 pl-1 pr-1"
        >
          Magic Eden
        </a>
        <a
          href="https://digitaleyes.market/collections/Lucky%20Ants"
          target="_blank"
          rel="noreferrer"
          className="underline text-blue-500 pr-1"
        >
          Digital Eyes
        </a>
        to purchase a Lucky Ant.
      </div>
    ),
  },
  {
    question: 'How do the armadillos capture ants?',
    answer: (
      <div>
        Once we are listed on the marketplaces, our community wallet is setup to receive 2% of all
        sales and the fund will be used to arm the armadillos. At random times, we will buy Lucky
        Ants listed at floor price across the marketplaces to raise the floor. A page dedicated to
        consumed ants will be available in the near future.
      </div>
    ),
  },
  {
    question: 'What is the Jailbreak?',
    answer: (
      <div>
        With the Lucky Ants locked away in armadillos&apos; nest, not all ants will be consumed. We
        will run a rescue mission (raffle) every week to rescue ONE Lucky Ant and give it to a Lucky
        Ant holder. As long as you have a Lucky Ant in your wallet, you will be eligible for the
        raffle! Do you have the bravest and luckiest ant in your wallet to rescue another ant?
      </div>
    ),
  },
  {
    question: 'Does owning multiple ants increase my odds in the raffle?',
    answer: (
      <div>
        No, we want to ensure that we have a balanced community and do not want a large number of
        ants concentrated in a small number of wallets. Our algorithm will only consider each unique
        wallet address once.
      </div>
    ),
  },
  {
    question: 'What are the Antlings?',
    answer: (
      <div>
        Antlings are a SEPARATE collection we have in the work to be released next year. The
        antlings are available ONLY to Lucky Ant holders and they will be distinct in look and art
        style from their parents. Stay tuned for additional details!
      </div>
    ),
  },
  {
    question: 'What are the upcoming cosmetic changes for minted ants?',
    answer: (
      <div>
        We have received feedback that some of our Ants are hard to see due to bright background. We
        are working on updating background color to be more neutral colors and reduce the size of
        ants so that they fit in profile frames better. Everything else would remain the same. Join
        our discord to stay abreast with the updates as we will solicit community feedback before
        uploading the changes to the blockchain.
      </div>
    ),
  },
  {
    question: 'What is our community wallet address?',
    answer: (
      <div>
        <CommunityAddress />
      </div>
    ),
  },
];

const Faqs = () => {
  return (
    <div id="#faqs" className="pt-16">
      <h3 className="text-md font-bold text-lg sm:text-xl md:text-2xl tracking-tight pb-3">FAQs</h3>
      {QandA.map((QandA, index) => (
        <div className="pt-4 pb-4" key={index}>
          <h3 className="font-bold text-lg sm:text-xl md:text-2xl tracking-tight opacity-40">
            {QandA.question}
          </h3>
          <div className="pt-2">{QandA.answer}</div>
        </div>
      ))}
    </div>
  );
};

export default Faqs;
